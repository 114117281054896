<template>
  <div class="phone mx-auto">
    <div class="container" id="js_container">
      <div class="content">

        <h1>Mock</h1>
        <h2>Mock app does nothing for you, it does not exist and is yellow..</h2>

        <button type="button" id="js_show-navigation">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
        </button>
      </div>
      <div class="menu">
        <h1>Menu</h1>

        <div class="button-container">
          <button type="button" id="js_hide-navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </div>
        <nav>
          <ul>
            <li><a href="#0">Home</a></li>
            <li><a href="#0">About</a></li>
            <li><a href="#0">Contact</a></li>
            <li><a href="#0">Dance</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>




</template>

<script>


export default {
  name: "MenuNr4",



  mounted() {

    const container  = document.getElementById('js_container')
    const showButton = document.getElementById('js_show-navigation')
    const hideButton = document.getElementById('js_hide-navigation')

    showButton.addEventListener('click', () => container.classList.add('--is-flipped'))
    hideButton.addEventListener('click', () => container.classList.remove('--is-flipped'))
  }}

</script>

<style scoped lang="scss">

h1 {
  font-family: "Abril Fatface", cursive;
  font-size: 5rem;
}

h2 {
  font-size: 1rem;
}

.phone {
  background: black;
  border-radius: 40px;
  border: 10px solid #21262b;
  box-shadow: 0 0 20px rgba(black, 0.4);
  height: 600px;
  overflow: hidden;
  perspective: 600px;
  position: relative;
  width: 310px;

  &::before {
    background: #21262b;
    content: "";
    height: 20px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 50%;
    z-index: 2;
    border-radius: 0 0 15px 15px;
  }

  .container {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    transform-style: preserve-3d;

    &.--is-flipped {
      transform: rotateY(180deg);

      .menu button {
        transform: scale(1);
      }

      nav,
      h1 {
        opacity: 1;
        animation: animationFrames linear 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;

        @keyframes animationFrames {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
    }
  }

  .content,
  .menu {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    padding: 2em;
    display: flex;
    flex-direction: column;

    button {
      position: absolute;
      bottom: 2em;
      outline: none;
      right: 2em;
      border-radius: 100%;
      width: 5em;
      backface-visibility: hidden;
      height: 5em;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
      display: flex;
      align-items:center;
      justify-content: center;

      svg {
        display: block;
      }
    }
  }

  .content {
    background: #ffd10f;
  }

  .menu {
    background: #2e2ebc;
    transform: rotateY(180deg);

    * {
      backface-visibility: hidden;
    }

    button {
      transform: scale(0);
      transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-delay: 0.8s;
      background-color: white;
    }

    h1 {
      color: white;
    }

    nav {
      opacity: 0;

      li a {
        font-size: 2rem;
        color: white;
      }
    }
  }
}

</style>